export enum IB_ROUTES {
  BASE = '/',
  AUTHENTICATED = 'a',
  NOT_AUTHENTICATED = 'na',
  SIGN_UP = 'sign-up',
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  DASHBOARD = 'dashboard',
  ACTIVITY = 'activity',
  WITHDRAW = 'withdraw',
  EMAIL_CONFIRMATION = 'email-confirmation',
  CHECK_REGISTR_EMAIL_LINK = 'check-email-link',
  PAGE_NOT_FOUND = 'page-not-found', // external
}
